<template>
    <div class="pt-1 px-3">
        <div class="">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="m-auto position-relative">
                <img :src="url ? url : (input_data.user_picture ? input_data.user_picture : default_img)" id="user_preview" class="bg-white" style="border-radius: 10px;height:180px;width:180px;object-fit: cover;" />
                <div @click="selectPicture()" class="position-absolute d-flex rounded-circle" style="bottom: 10px;right: 10px;background-color: #1B628C;padding:.5em">
                    <i class="bx bxs-camera m-auto" style="font-size: 18px;color: white;"></i>
                </div>
                <form id="form">
                    <input type="file" @change="onFileChange" name="user_picture" id="user_picture" class="d-none" accept="image/*" />
                </form>
            </div>
        </div>
        <div class="d-flex mt-1">
            <div class="badge badge-danger text-white m-auto" v-if="(!url && !input_data.user_picture) && isSubmit" 
                style="margin-top: .5em;">User picture not valid</div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Email</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-envelope"></i></span>
                <input class="form-control" placeholder="Email" v-model="user_email" disabled>
            </div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Name</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-user"></i></span>
                <input class="form-control" placeholder="Name" v-model="input_data.user_name" maxlength="40">
            </div>
            <div class="text-white" style="margin-top: .5em;">*Username is atleast 8 character</div>
            <div class="badge badge-danger text-white" v-if="!valid.user_name && isSubmit" style="margin-top: .5em;">Username not valid, atleast 8 character</div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Location</label>
            <div class="input-group">
                <span class="input-group-text text-white"><i class="bx bx-map"></i></span>
                <input class="form-control" placeholder="Location" v-model="input_data.user_location">
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.user_location && isSubmit" style="margin-top: .5em;">Location is not valid</div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Mobile Number</label>
            <div class="d-flex" style="gap:10px 15px">
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-phone"></i></span>
                    <input class="form-control" placeholder="Mobile Number" type="number" v-model="input_data.user_phone">
                </div>
            </div>
            <div class="badge badge-danger text-white" v-if="!valid.user_phone && isSubmit" style="margin-top: .5em;">Phone is not valid</div>
        </div>

        <div class="mt-2">
            <label class="h3 text-white">Country</label>
            <div class="d-flex" style="gap:10px 15px">
                <div class="input-group" >
                    <!-- <span class="input-group-text"></span> -->
                    <select class="form-control" v-model="input_data.country" style="background: transparent;border:unset;border-bottom: 1px solid white !important;color:white">
                        <option value="indonesia">Indonesia</option>
                        <option value="singapore">Singapore</option>
                        <option value="thailand">Thailand</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mt-3 w-100 d-flex flex-column justify-content-center" style="gap:10px">
            <button class="btn text-white flex-fill" @click="onSubmit()" style="background-color: #187294;height:50px;font-size: 18px;">
                Save Profile
            </button> 
        </div>

    </div>
</template>

<script>

import Swal from 'sweetalert2'
import store from '@/store'
import Cookies from 'universal-cookie'
const cookies = new Cookies()  

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        valid(){
            return {
                user_name : this.input_data.user_name?.length > 7,
                user_phone : this.input_data.user_phone?.length > 7,
                user_location : this.input_data.user_location?.length > 1,
                picture: this.url || this.input_data.user_picture?.length > 1
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        user(val){
            this.input_data = val
            this.user_email = val.user_email
        }
    },
    mounted(){
        if(this.user){
            this.input_data = this.user
            this.user_email = this.user.user_email
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        onFileChange(e) {
            const file = e.target.files[0];

            let size = parseInt("1")

            if(((file.size/1024)/1024).toFixed(4) > size){
                Swal.fire({
                    title: 'Warning!',
                    text: 'File size is to big, maximum is '+size+'MB!',
                    icon: 'error',
                    confirmButtonColor: '#163485',
                    confirmButtonText: 'Ok!',
                })
                $('#user_picture').val('')
            } else {
                this.url = URL.createObjectURL(file);
            }

        },
        selectPicture(){
            $('#user_picture').click()
        },
        onSubmit(){
            this.isSubmit = true
            if(this.allValid){
                let formData = new FormData($('#form')[0])
                formData.append('inp[user_name]', this.input_data.user_name)
                formData.append('inp[user_location]', this.input_data.user_location)
                formData.append('inp[user_phone]', this.input_data.user_phone)  
                formData.append('inp[country]', this.input_data.country)
                formData.append('id', cookies.get('token'))
                store.dispatch('auth/SaveUser', formData).then(() => {
                    store.dispatch('auth/GetUserData')
                })
            }
        }
    },
    data(){
        return {
            input_data: {
                user_name : '',
                user_phone: '',
                user_location: '',
                user_picture: '',
                country: ''
            },
            user_email: '',
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            isSubmit: false,
            url: null
        }
    }
}


$('input[type="file"]').change(function(e){
    var fileName = $(this).val();

    text = fileName.substring(fileName.lastIndexOf("\\") + 1, fileName.length);

    
})

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 40px;
        color: white;
        background-color: transparent !important;
    }  
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>